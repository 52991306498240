.chat-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px); /* Subtract the height of the navbar */
  }
  
  .send-message-form {
    display: flex;
    padding: 1rem;
  }
  
  .send-message-form input {
    flex: 1;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    font-size: 16px;
  }
  
  .send-message-form input::placeholder {
    color: #999;
  }

.typing {
  display: inline-block;
  overflow: hidden;
  width: 70%;  
}

.typing > .message-content::after {
  content: "|"; /* use the pipe symbol to represent the cursor */
  display: inline-block;
  width: 0.5em; /* adjust the width of the cursor to your liking */
  animation: blink 1s infinite; /* apply the "blink" animation to the cursor */
}

@keyframes blink {
  50% {
    opacity: 0; /* make the cursor invisible at 50% of the animation */
  }
}