.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    height: 60px;
    padding: 0 1rem;
}
  
.logo {
    height: 30px;
    width: auto;
}

.nav-title {
    display: flex;
    height: 60px;
    color: white;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
}
  

.refresh-button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}