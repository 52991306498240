.message-list {
  flex: 1;
  white-space: pre-wrap;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message-list > div > div:first-child {
	margin-top: 1rem;   
}


.message {
  margin-bottom: 1rem;
  max-width: 70%;
}

.message.user {
  align-self: flex-end;
  text-align: right;
  background-color: #007bff;
  color: #fff;
  border-radius: 0.75rem 0.75rem 0 0.75rem;
  padding: 0.5rem;
  margin-right: 1rem;
}

.message.assistant {
  align-self: flex-start;
  background-color: #f1f1f1;
  border-radius: 0.75rem 0.75rem 0.75rem 0;
  padding: 0.5rem;
  text-align: left;
  margin-left: 1rem;
}

.message-list > div {
  display: flex !important;
  flex-direction: column;
}

.message-list .demo-prompt {
  text-align: left;
  color: #808081;
  padding: 0.5rem;
  font-weight: bold;
}
