body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px); /* Subtract the height of the navbar */
  }
  
  .send-message-form {
    display: flex;
    padding: 1rem;
  }
  
  .send-message-form input {
    flex: 1 1;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    font-size: 16px;
  }
  
  .send-message-form input::-webkit-input-placeholder {
    color: #999;
  }
  
  .send-message-form input::placeholder {
    color: #999;
  }

.typing {
  display: inline-block;
  overflow: hidden;
  width: 70%;  
}

.typing > .message-content::after {
  content: "|"; /* use the pipe symbol to represent the cursor */
  display: inline-block;
  width: 0.5em; /* adjust the width of the cursor to your liking */
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite; /* apply the "blink" animation to the cursor */
}

@-webkit-keyframes blink {
  50% {
    opacity: 0; /* make the cursor invisible at 50% of the animation */
  }
}

@keyframes blink {
  50% {
    opacity: 0; /* make the cursor invisible at 50% of the animation */
  }
}
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    height: 60px;
    padding: 0 1rem;
}
  
.logo {
    height: 30px;
    width: auto;
}

.nav-title {
    display: flex;
    height: 60px;
    color: white;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
}
  

.refresh-button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}
.message-list {
  flex: 1 1;
  white-space: pre-wrap;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message-list > div > div:first-child {
	margin-top: 1rem;   
}


.message {
  margin-bottom: 1rem;
  max-width: 70%;
}

.message.user {
  align-self: flex-end;
  text-align: right;
  background-color: #007bff;
  color: #fff;
  border-radius: 0.75rem 0.75rem 0 0.75rem;
  padding: 0.5rem;
  margin-right: 1rem;
}

.message.assistant {
  align-self: flex-start;
  background-color: #f1f1f1;
  border-radius: 0.75rem 0.75rem 0.75rem 0;
  padding: 0.5rem;
  text-align: left;
  margin-left: 1rem;
}

.message-list > div {
  display: flex !important;
  flex-direction: column;
}

.message-list .demo-prompt {
  text-align: left;
  color: #808081;
  padding: 0.5rem;
  font-weight: bold;
}

@-webkit-keyframes primary-indeterminate-translate {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
            animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
            animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    -webkit-transform: translateX(83.67142%);
            transform: translateX(83.67142%);
  }
  100% {
    -webkit-transform: translateX(200.611057%);
            transform: translateX(200.611057%);
  }
}
@keyframes primary-indeterminate-translate {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
            animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
            animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    -webkit-transform: translateX(83.67142%);
            transform: translateX(83.67142%);
  }
  100% {
    -webkit-transform: translateX(200.611057%);
            transform: translateX(200.611057%);
  }
}
@-webkit-keyframes primary-indeterminate-scale {
  0% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08);
  }
  36.65% {
    -webkit-animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
            animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08);
  }
  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
            animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    -webkit-transform: scaleX(0.661479);
            transform: scaleX(0.661479);
  }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08);
  }
}
@keyframes primary-indeterminate-scale {
  0% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08);
  }
  36.65% {
    -webkit-animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
            animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08);
  }
  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
            animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    -webkit-transform: scaleX(0.661479);
            transform: scaleX(0.661479);
  }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08);
  }
}
@-webkit-keyframes secondary-indeterminate-translate {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
            animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
            animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    -webkit-transform: translateX(37.651913%);
            transform: translateX(37.651913%);
  }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
            animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    -webkit-transform: translateX(84.386165%);
            transform: translateX(84.386165%);
  }
  100% {
    -webkit-transform: translateX(160.277782%);
            transform: translateX(160.277782%);
  }
}
@keyframes secondary-indeterminate-translate {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
            animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
            animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    -webkit-transform: translateX(37.651913%);
            transform: translateX(37.651913%);
  }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
            animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    -webkit-transform: translateX(84.386165%);
            transform: translateX(84.386165%);
  }
  100% {
    -webkit-transform: translateX(160.277782%);
            transform: translateX(160.277782%);
  }
}
@-webkit-keyframes secondary-indeterminate-scale {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
            animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08);
  }
  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
            animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
    -webkit-transform: scaleX(0.457104);
            transform: scaleX(0.457104);
  }
  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
            animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
    -webkit-transform: scaleX(0.72796);
            transform: scaleX(0.72796);
  }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08);
  }
}
@keyframes secondary-indeterminate-scale {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
            animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08);
  }
  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
            animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
    -webkit-transform: scaleX(0.457104);
            transform: scaleX(0.457104);
  }
  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
            animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
    -webkit-transform: scaleX(0.72796);
            transform: scaleX(0.72796);
  }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08);
  }
}
@-webkit-keyframes buffering {
  to {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
}
@keyframes buffering {
  to {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
}
@-webkit-keyframes primary-indeterminate-translate-reverse {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
            animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
            animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    -webkit-transform: translateX(-83.67142%);
            transform: translateX(-83.67142%);
  }
  100% {
    -webkit-transform: translateX(-200.611057%);
            transform: translateX(-200.611057%);
  }
}
@keyframes primary-indeterminate-translate-reverse {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
            animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
            animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    -webkit-transform: translateX(-83.67142%);
            transform: translateX(-83.67142%);
  }
  100% {
    -webkit-transform: translateX(-200.611057%);
            transform: translateX(-200.611057%);
  }
}
@-webkit-keyframes secondary-indeterminate-translate-reverse {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
            animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
            animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    -webkit-transform: translateX(-37.651913%);
            transform: translateX(-37.651913%);
  }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
            animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    -webkit-transform: translateX(-84.386165%);
            transform: translateX(-84.386165%);
  }
  100% {
    -webkit-transform: translateX(-160.277782%);
            transform: translateX(-160.277782%);
  }
}
@keyframes secondary-indeterminate-translate-reverse {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
            animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
            animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    -webkit-transform: translateX(-37.651913%);
            transform: translateX(-37.651913%);
  }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
            animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    -webkit-transform: translateX(-84.386165%);
            transform: translateX(-84.386165%);
  }
  100% {
    -webkit-transform: translateX(-160.277782%);
            transform: translateX(-160.277782%);
  }
}
@-webkit-keyframes buffering-reverse {
  to {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}
@keyframes buffering-reverse {
  to {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}
.mdc-linear-progress {
  position: relative;
  width: 100%;
  height: 4px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  overflow: hidden;
}
.mdc-linear-progress__bar {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: none;
          animation: none;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-linear-progress__bar-inner {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: none;
          animation: none;
}
.mdc-linear-progress__buffering-dots {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: buffering 250ms infinite linear;
          animation: buffering 250ms infinite linear;
  background-repeat: repeat-x;
  background-size: 10px 4px;
}
.mdc-linear-progress__buffer {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-linear-progress__primary-bar {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}
.mdc-linear-progress__secondary-bar {
  visibility: hidden;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
  transition: none;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  left: -145.166611%;
  -webkit-animation: primary-indeterminate-translate 2s infinite linear;
          animation: primary-indeterminate-translate 2s infinite linear;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
  -webkit-animation: primary-indeterminate-scale 2s infinite linear;
          animation: primary-indeterminate-scale 2s infinite linear;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  left: -54.888891%;
  -webkit-animation: secondary-indeterminate-translate 2s infinite linear;
          animation: secondary-indeterminate-translate 2s infinite linear;
  visibility: visible;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
  -webkit-animation: secondary-indeterminate-scale 2s infinite linear;
          animation: secondary-indeterminate-scale 2s infinite linear;
}
.mdc-linear-progress--reversed .mdc-linear-progress__bar,
.mdc-linear-progress--reversed .mdc-linear-progress__buffer {
  right: 0;
  -webkit-transform-origin: center right;
          transform-origin: center right;
}
.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
  -webkit-animation-name: primary-indeterminate-translate-reverse;
          animation-name: primary-indeterminate-translate-reverse;
}
.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
  -webkit-animation-name: secondary-indeterminate-translate-reverse;
          animation-name: secondary-indeterminate-translate-reverse;
}
.mdc-linear-progress--reversed .mdc-linear-progress__buffering-dots {
  -webkit-animation: buffering-reverse 250ms infinite linear;
          animation: buffering-reverse 250ms infinite linear;
}
.mdc-linear-progress--closed {
  opacity: 0;
}

.mdc-linear-progress__bar-inner {
  background-color: #007bff;
  /* @alternate */
  background-color: #007bff;
  background-color: var(--mdc-theme-primary, #007bff);
}

.mdc-linear-progress__buffering-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E");
}

.mdc-linear-progress__buffer {
  background-color: #e6e6e6;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
  right: -145.166611%;
  left: auto;
}
.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
  right: -54.888891%;
  left: auto;
}

.hero-linear-progress-indicator {
  width: 100%;
}

.demo-linear-progress-indicator {
  margin-top: 32px;
}



